import * as Sentry from "@sentry/browser";

document.addEventListener("DOMContentLoaded", function () {
  const bodyData = document.querySelector("body").dataset;

  if (["staging", "production"].includes(bodyData.environment)) {
    Sentry.init({
      dsn: bodyData["sentryDsn"],
      environment: bodyData["environment"],
      release: "kiu3 " + bodyData["version"],
      ignoreErrors: ["ResizeObserver loop limit exceeded"],
      integrations: [Sentry.browserTracingIntegration()],
      tracesSampleRate: 0.1
    });

    Sentry.getCurrentScope().setUser({ id: bodyData["id"] });
  }
});
