import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

let tryCount = 0;
const maxRetries = 3;

export default class extends Controller {
  static targets = ["form", "checkbox"];

  checkboxTargetConnected() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = true;
      checkbox.addEventListener("change", () => {
        this.search();
      });
    });
  }

  sort(e) {
    const url = e.target.closest("button").dataset.url;

    this.newAjaxRequest(url);
  }

  search() {
    const formData = new FormData(this.formTarget);
    const params = new URLSearchParams(formData).toString();

    this.newAjaxRequest(`?${params}`);
  }

  newAjaxRequest(url) {
    Rails.ajax({
      url: url,
      type: "get",
      dataType: "json",
      success: (data) => {
        this.handleNewQuery(data.html);
      },
      error: () => {
        tryCount += 1;
        if (tryCount < maxRetries) {
          this.search();
        }
      }
    });
  }

  handleNewQuery(html) {
    document.getElementById("participants-table").innerHTML = html;
  }
}
