import { Controller } from "@hotwired/stimulus";
import { MDCTopAppBar } from "@material/top-app-bar";
import { MDCDrawer } from "@material/drawer";

export default class extends Controller {
  connect() {
    this.topAppBar = new MDCTopAppBar(this.element);
    this.drawerMenu();
  }

  disconnect() {
    if (this.topAppBar) {
      this.topAppBar.destroy();
    }
  }

  drawerMenu() {
    const drawerElement = document.querySelector(".mdc-drawer");

    if (drawerElement) {
      const drawer = MDCDrawer.attachTo(drawerElement);
      this.topAppBar.setScrollTarget(document.querySelector("main"));
      this.topAppBar.listen("MDCTopAppBar:nav", () => {
        drawer.open = !drawer.open;
      });
    }
  }
}
