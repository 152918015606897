import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "icon"];

  toggle() {
    let type =
      this.inputTarget.getAttribute("type") === "password"
        ? "text"
        : "password";
    this.inputTarget.setAttribute("type", type);
    this.iconTarget.classList.toggle("fa-eye");
    this.iconTarget.classList.toggle("fa-eye-slash");
  }
}
