import createAlert from "../shared/alert";
import * as Sentry from "@sentry/browser";

document.body.addEventListener("ajax:complete", (event) => {
  const [xhr, status] = event.detail;
  if (isJsonString(xhr.responseText)) {
    notifyResults(xhr);
  }
});

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export default class RailsAjax {
  static ajax(method, url, data, Document, Xhr) {
    let doc = Document == null ? document : Document;
    let xhr = Xhr == null ? new XMLHttpRequest() : Xhr;
    const csrfElement = doc.head.querySelector("[name~=csrf-token][content]");

    if (csrfElement == null) {
      Sentry.addBreadcrumb({
        message: "document head HTML: " + doc.head.outerHTML,
        level: "info"
      });
    }

    const csrfToken = csrfElement.content;

    xhr.onreadystatechange = handleResponse;
    xhr.open(method, url);
    xhr.setRequestHeader("Content-Type", "application/json");
    data.authenticity_token = csrfToken;

    xhr.send(JSON.stringify(data));
    return xhr;

    function handleResponse() {
      const done =
        typeof XMLHttpRequest == "undefined" ? Xhr.DONE : XMLHttpRequest.DONE;
      if (xhr.readyState === done) {
        if (xhr.status == 403) {
          alert("Oops! Are you still signed into the VPN?");
          return;
        }

        notifyResults(xhr);
      }
    }
  }

  static get(url, data, Document, Xhr) {
    return this.ajax("GET", url, data, Document, Xhr);
  }

  static delete(url, data, Document, Xhr) {
    return this.ajax("DELETE", url, data, Document, Xhr);
  }

  static post(url, data, Document, Xhr) {
    return this.ajax("POST", url, data, Document, Xhr);
  }

  static update(url, data, Document, Xhr) {
    return this.ajax("PATCH", url, data, Document, Xhr);
  }
}

function notifyResults(xhr) {
  // We want JSON.parse to blow up if the response is malformed JSON,
  // but empty responses will be ignored.
  if (
    xhr.status == 0 ||
    xhr.status == 422 ||
    xhr.responseText == null ||
    xhr.responseText == ""
  ) {
    return;
  }

  let message = {};
  try {
    message = JSON.parse(xhr.responseText);
  } catch (error) {
    Sentry.addBreadcrumb({
      message: "invalid JSON received: " + xhr.responseText.substr(0, 30),
      level: "error"
    });
    Sentry.addBreadcrumb({
      message:
        "HTTP Status and URL info: " + xhr.status + " " + xhr.responseUrl,
      level: "error"
    });
    Sentry.captureException(error);
  }

  if (message.userMessage == null) return;

  let alertPlaceholder = document.getElementById("alert-placeholder");
  let className = xhr.status !== 200 ? "danger" : "info";

  if (alertPlaceholder == null) {
    Sentry.captureMessage("Alert Placeholder Missing");
  } else {
    alertPlaceholder.innerHTML = message.userMessage;
  }
}
