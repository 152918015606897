import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["file", "label"];

  add(event) {
    event.preventDefault();
    this.fileTarget.click();
  }

  updateLabel() {
    if (this.fileTarget.files.length) {
      this.labelTarget.innerHTML = this.fileTarget.files[0].name;
    }
  }
}
