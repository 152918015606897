import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus";

let currentFieldset, nextFieldset, navIndex;

export default class extends Controller {
  static get targets() {
    return [
      "branchingAnswer",
      "branchingAnswerPair",
      "feedbackAnswer",
      "feedbackResponse",
      "form",
      "formStep",
      "navStep",
      "nextButton",
      "checkbox"
    ];
  }

  checkboxTargetConnected() {
    this.checkboxTargets.forEach((checkbox) => {
      if (!checkbox.disabled) {
        checkbox.required = true;
      }
    });
  }

  showElement(elId) {
    let el = document.getElementById(elId);
    el.classList.remove("hidden");
    el.querySelectorAll("input").forEach((input) => {
      input.disabled = false;
      if (input.offsetParent !== null) {
        input.offsetParent.classList.remove("mdc-text-field--disabled");
      }
    });
  }

  hideElement(elId) {
    let el = document.getElementById(elId);
    el.classList.add("hidden");
    el.querySelectorAll("input").forEach((input) => {
      input.disabled = true;
    });
  }

  handleBranchingSingle() {
    this.branchingAnswerTargets.forEach((answer) => {
      if (answer.checked && answer.value == answer.dataset.showValue) {
        this.showElement(answer.dataset.showId);
      } else if (answer.checked && answer.value != answer.dataset.showValue) {
        this.hideElement(answer.dataset.showId);
      }
    });
  }

  handleBranchingPair() {
    let inputs = this.branchingAnswerPairTarget.querySelectorAll("input");
    let showConditions = JSON.parse(
      this.branchingAnswerPairTarget.dataset.showConditions
    );
    let inputResponses = [];

    inputs.forEach((input) => inputResponses.push(input));
    inputResponses = inputResponses
      .filter((input) => input.checked)
      .map((input) => input.value);

    if (inputResponses.length != 2) {
      return;
    }

    this.hideElement(this.branchingAnswerPairTarget.dataset.showId);

    showConditions.forEach((conditions) => {
      if (
        inputResponses[0] == conditions[0] &&
        inputResponses[1] == conditions[1]
      ) {
        this.showElement(this.branchingAnswerPairTarget.dataset.showId);
      }
    });
  }

  handleFeedback(e) {
    this.feedbackAnswerTargets.forEach((answer) => {
      let map = JSON.parse(answer.dataset.feedbackMap);

      this.feedbackResponseTargets.forEach((feedback) => {
        feedback.classList.add("hidden");
      });

      if (e.target.checked) {
        this.showElement(this.getFeedbackId(map, e.target.value));
      }
    });
  }

  getFeedbackId(map, val) {
    return map.find((mapping) => {
      return mapping.label == val;
    }).feedback_id;
  }

  nextStep(e) {
    if (
      this.formStepTargets[1].offsetParent !== null &&
      e.target.offsetParent.dataset.submit === "true"
    ) {
      if (this.formTarget.reportValidity()) {
        Rails.fire(this.formTarget, "submit");
        this.navNext();
      }
    } else {
      this.navNext();
    }
  }

  navNext() {
    this.formStepTargets.forEach((step, i) => {
      if (step.offsetParent !== null) {
        currentFieldset = step;
        nextFieldset = step.nextElementSibling;
        navIndex = i;
      }
    });

    if (nextFieldset === null) {
      window.location.href =
        window.location.origin + this.nextButtonTarget.dataset.nextPath;
      return;
    }

    this.navStepTargets[navIndex].classList.remove("active");
    this.navStepTargets[navIndex + 1].classList.add("active");
    this.navStepTargets[navIndex + 1].classList.add("activated");

    nextFieldset.style.display = "block";
    currentFieldset.style.display = "none";
  }

  requiredCheckboxes(e) {
    let checkboxGroupClass = e.target.dataset.multiGroupId;
    let groupCheckboxes = document.querySelectorAll(
      `[data-multi-group-id="${checkboxGroupClass}"]`
    );
    let atLeastOneChecked = Array.prototype.some.call(
      groupCheckboxes,
      (groupCheckbox) => {
        return groupCheckbox.checked;
      }
    );

    groupCheckboxes.forEach((groupCheckbox) => {
      groupCheckbox.required = !atLeastOneChecked;
    });
  }
}
