import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["query", "card"];

  search() {
    let query = this.queryTarget.value.toLowerCase();
    this.showElements();
    if (query === "") {
      return;
    }

    this.cardTargets.forEach((card) => {
      if (!card.innerHTML.toLowerCase().includes(query)) {
        card.style.display = "none";
      }
    });
  }

  reset() {
    this.queryTarget.value = "";
    this.showElements();
  }

  showElements() {
    this.cardTargets.forEach((card) => {
      card.style.display = "block";
    });
  }
}
