import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

let tryCount = 0;
const maxRetries = 3;

export default class extends Controller {
  sort(e) {
    const url = e.target.closest("button").dataset.url;
    const key = e.target.closest("button").dataset.key.replace(/_/g, "-");
    Rails.ajax({
      url: url,
      type: "get",
      dataType: "json",
      success: (data) => {
        this.handleNewQuery(data.html, key);
      },
      error: () => {
        tryCount += 1;
        if (tryCount < maxRetries) {
          this.sort(e);
        }
      }
    });
  }

  handleNewQuery(html, key) {
    document.getElementById(`${key}-participants-table`).innerHTML = html;
  }
}
