const accordion = document.querySelector(".accordion");

if (accordion) {
  const headers = document.querySelectorAll("[data-toggle='collapse']");

  headers.forEach((header) => {
    header.addEventListener("click", () => {
      let content = document.querySelector(header.dataset.target);

      if (header.classList.contains("collapsed")) {
        header.classList.remove("collapsed");
        header.setAttribute("aria-expanded", "true");

        content.classList.remove("collapse");
        content.classList.add("collapsing");
        content.style.maxHeight = content.scrollHeight + "px";

        setTimeout(() => {
          content.classList.remove("collapsing");
          content.classList.add("collapse", "show");
        }, 350);
      } else {
        header.classList.add("collapsed");
        header.setAttribute("aria-expanded", "false");

        content.style.maxHeight = content.scrollHeight + "px";
        content.classList.remove("collapse", "show");
        content.classList.add("collapsing");

        setTimeout(() => {
          content.style.maxHeight = "0";

          setTimeout(() => {
            content.classList.remove("collapsing");
            content.classList.add("collapse");
          }, 350);
        }, 10);
      }

      setTimeout(() => {
        checkVisibility(header, content);
      }, 350);
    });
  });

  const checkVisibility = (header, content) => {
    if (
      header.classList.contains("collapsed") &&
      content.classList.contains("show")
    ) {
      content.classList.remove("show");
    }
  };
}
