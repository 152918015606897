import { Controller } from "@hotwired/stimulus";
import { MDCList } from "@material/list";
import { MDCRipple } from "@material/ripple";

let question_data = {
  0: { 0: 0, 1: 0, 2: 1, 3: 2, 4: 2, 5: 1, 6: 0, 7: 1, 8: 2 },
  1: { 0: 1, 1: 1, 2: 1, 3: 1, 4: 1, 5: 2, 6: 2, 7: 2, 8: 1 },
  2: { 0: 0, 1: 2, 2: 0, 3: 2, 4: 2, 5: 1, 6: 1, 7: 1, 8: 2 },
  3: { 0: 1, 1: 1, 2: 1, 3: 2, 4: 1, 5: 1, 6: 1, 7: 1, 8: 1 },
  4: { 0: 1, 1: 1, 2: 2, 3: 1, 4: 1, 5: 2, 6: 1, 7: 2, 8: 2 },
  5: { 0: 1, 1: 2, 2: 0, 3: 0, 4: 1, 5: 0, 6: 0, 7: 2, 8: 0 },
  6: { 0: 1, 1: 1, 2: 1, 3: 1, 4: 1, 5: 1, 6: 0, 7: 1, 8: 1 },
  7: { 0: 2, 1: 2, 2: 2, 3: 2, 4: 1, 5: 1, 6: 1, 7: 1, 8: 2 }
};

let colors = {
  0: "red",
  1: "green"
};

export default class extends Controller {
  static get targets() {
    return ["checkbox", "listLabel"];
  }

  connect() {
    this.list = new MDCList(this.element);
    this.ripples = [];

    this.list.listElements.forEach((listItem) => {
      this.initializeRipple(listItem);

      listItem.addEventListener("click", (e) => {
        e.stopPropagation();
        this.checkTheBox(listItem);
        this.checkLocation();
      });
    });
  }

  listLabelTargetConnected() {
    for (let listLabel of this.listLabelTargets) {
      listLabel.addEventListener("click", (e) => {
        e.stopPropagation();
        this.checkTheBox(listLabel);
        this.checkLocation();
      });
    }
  }

  checkboxTargetConnected() {
    for (let checkbox of this.checkboxTargets) {
      setTimeout(() => {
        if (
          ["1", "3", "6"].includes(checkbox.id) &&
          checkbox.checked !== true
        ) {
          checkbox.checked = true;
        }
      }, 0);

      checkbox.addEventListener("click", () => {
        if (checkbox.checked) {
          checkbox.checked = false;
        } else {
          checkbox.checked = true;
        }
        this.checkLocation();
      });
    }
  }

  checkTheBox(listElement) {
    for (let checkbox of this.checkboxTargets) {
      if (checkbox.checked && checkbox.id === listElement.dataset.checkboxId) {
        checkbox.checked = false;
      } else if (
        !checkbox.checked &&
        checkbox.id === listElement.dataset.checkboxId
      ) {
        checkbox.checked = true;
      }
    }
  }

  checkLocation() {
    for (let i = 0; i <= 8; i++) {
      let value = 1;

      for (let checkbox of this.checkboxTargets) {
        if (
          (checkbox.checked && !checkbox.classList.contains("no_question")) ||
          (!checkbox.checked && checkbox.classList.contains("no_question"))
        ) {
          value *= question_data[checkbox.id][i];
        }
      }

      document.querySelector(`#place_list div:nth-child(${i + 1})`).className =
        colors[value] || "yellow";
    }
  }

  disconnect() {
    if (this.list) {
      this.list.destroy();
    }

    this.ripples.forEach((ripple) => {
      ripple.destroy();
    });
  }

  initializeRipple(el) {
    this.ripples.push(new MDCRipple(el));
  }
}
