import { Controller } from "@hotwired/stimulus";
import { MDCDialog } from "@material/dialog";

export default class extends Controller {
  static targets = ["container"];

  openModal(e) {
    e.preventDefault();

    fetch(e.currentTarget.dataset.url)
      .then((res) => {
        return res.text();
      })
      .then((html) => {
        if (!this.modal) {
          document.getElementById("modal-form-container").innerHTML = html;
          this.modal = new MDCDialog(
            document.getElementById("admin-modal-form")
          );
        }
        this.modal.open();
      });
  }
}
