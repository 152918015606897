import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  loadRerender(e) {
    Rails.ajax({
      url: e.target.parentElement.href,
      type: "get",
      dataType: "json",
      success: (data) => {
        this.handleNewResponse(data.html, this.element.dataset.rerenderTarget);
      }
    });
  }

  handleNewResponse(html, node) {
    document.querySelector(node).innerHTML = html;
  }
}
